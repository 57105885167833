/* eslint-disable import/no-extraneous-dependencies */
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueYouTubeEmbed from 'vue-youtube'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(Vuex)
Vue.config.productionTip = false
Vue.use(VueSweetalert2)
Vue.use(VueYouTubeEmbed)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
