import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    listEmployees: [],
    profilePhoto: '',
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getListEmployees(state) {
      return state.listEmployees
    },
  },
  mutations: {
    getUserProfile: (state, item) => {
      state.user = item
    },
    getProfilePhoto: (state, item) => {
      state.profilePhoto = item
    },
    getListEmployees: (state, item) => {
      state.listEmployees = item
    },
  },
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
