const Sgq = {
  path: '/sgq',
  component: () => import('@/views/pages/sgqSystem/routes/Sgq.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'home',
      name: 'sgq-home',
      component: () => import('@/views/pages/sgqSystem/RIC/attendance/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'users-attendance',
      name: 'sgq-user-by-ric',
      component: () => import('@/views/pages/sgqSystem/RIC/UsersByRic/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'sac-attendance',
      name: 'sgq-sac',
      component: () => import('@/views/pages/sgqSystem/RIC/Sac/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'control-panel',
      name: 'sgq-control-panel',
      component: () => import('@/views/pages/sgqSystem/RIC/Adm/Administration.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'processes',
      name: 'sgq-processes',
      component: () => import('@/views/pages/sgqSystem/DocumentManagement/Processes/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'processes-documents',
      name: 'sgq-documents',
      component: () => import('@/views/pages/sgqSystem/DocumentManagement/Documents/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'processes-types',
      name: 'sgq-types',
      component: () => import('@/views/pages/sgqSystem/DocumentManagement/DocumentType/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'processes-files',
      name: 'sgq-files',
      component: () => import('@/views/pages/sgqSystem/DocumentManagement/Files/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'processes-documents-on-occupation',
      name: 'sgq-docs-on-occupation',
      component: () => import('@/views/pages/sgqSystem/DocumentManagement/DocumentOnOccupation/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'internal-complaint',
      name: 'sgq-internal-complaint',
      component: () => import('@/views/pages/sgqSystem/internalComplaint/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'dashboard',
      name: 'sgq-internal-complaint-dashboard',
      component: () => import('@/views/pages/sgqSystem/internalComplaint/Dashboard/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'reports/coaf',
      name: 'sgq-reports-coaf',
      component: () => import('@/views/pages/sgqSystem/Reports/COAF/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'reports/director-channel',
      name: 'sgq-reports-director-channel',
      component: () => import('@/views/pages/sgqSystem/Reports/DirectorChannel/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'documents-panel/:id',
      name: 'sgq-panel',
      component: () => import('@/views/pages/sgqSystem/Documents/Panel/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'documents-panel-companies',
      name: 'sgq-panel-companies',
      component: () => import('@/views/pages/sgqSystem/Documents/Panel/HomeCompanies.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'documents-tools',
      name: 'sgq-tools',
      component: () => import('@/views/pages/sgqSystem/Documents/Tools/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'request-person',
      name: 'person-request',
      component: () => import('@/views/pages/sgqSystem/PeopleManagement/PersonRequest/home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'employee-offboarding',
      name: 'employee-offboarding',
      component: () => import('@/views/pages/sgqSystem/PeopleManagement/EmployeeOffboarding/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
  ],
}

export default Sgq
