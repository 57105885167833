import {
  mdiCalendarMonthOutline, mdiCartVariant, mdiFileDocument, mdiViewDashboard,
  mdiWallet,
} from '@mdi/js'

export default [
  {
    title: 'DASHBOARD',
    icon: mdiViewDashboard,
    children: [
      {
        title: 'Vendedores',
        to: 'sigma-vendor',
      },
    ],
  },
  {
    subheader: 'Ferramentas do Vendedor',
  },
  {
    title: 'CARTEIRA',
    icon: mdiWallet,
    children: [
      {
        title: 'Clientes',
        to: 'sigma-client',
      },
      {
        title: 'Contratos',
        to: 'sigma-contract',
      },
    ],
  },
  {
    title: 'PÓS-VENDAS',
    icon: mdiCartVariant,
    children: [
      {
        title: 'Assembleia',
        to: 'sigma-assembly',
      },
      {
        title: 'Encerramento',
        to: 'sigma-closing-group',
      },
      {
        title: 'Aniversariante',
        to: 'sigma-birthday-person',
      },
      {
        title: 'Localizar Grupos',
        to: 'sigma-reserve-group',
      },
      {
        title: 'Loteria Federal',
        to: 'sigma-lottery',
      },
      {
        title: 'Carteira',
        to: 'wallet-client',
      },
    ],
  },
  {
    title: 'TERMO LGPD',
    icon: mdiFileDocument,
    children: [
      {
        title: 'Vendedor',
        to: 'sigma-lgpd-vendor',
      },
    ],
  },
  {
    title: 'CALENDÁRIO',
    icon: mdiCalendarMonthOutline,
    children: [
      {
        title: 'Atendimento',
        to: 'calendar-vendor',
      },
    ],
  },

]
