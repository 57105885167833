import { mdiMotorbike, mdiNotebook, mdiCardBulleted } from '@mdi/js'

export default [
  {
    subheader: 'Nexus App',
  },
  {
    title: 'Veículos',
    to: 'nexusapp-home',
    icon: mdiMotorbike,
  },
  {
    title: 'Planos',
    to: 'nexusapp-plains',
    icon: mdiNotebook,
  },
  {
    title: 'Parcelamentos',
    to: 'nexusapp-installments',
    icon: mdiCardBulleted,
  },

]
