import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
  app: {
    name: 'SIGMA',
    logo: require('@/assets/images/svg/logo.svg'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'semi-dark', // default, bordered, semi-dark
    contentWidth: 'full',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      purple: '#9a62fd',
      transparentBlack: '#20201f',
      primary: '#168ed7',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
      orange: '#e66800',
      brown: '#f3a427',
      blue: '#0085FF',
      yellowGold: '#FFE500',
      greenWeak: '#7DFFA9',
      greenDark: '#00AA4D',
      greenAvocado: '#A5DC86',
      lightPink: '#C38FDB',
    },
    dark: {
      purple: '#9a62fd',
      transparentBlack: '#20201f',
      primary: '#168ed7',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
      orange: '#ff821a',
      brown: '#f6ad55',
      companyYellow: '#FFBF3F',
      companyPurple: '#E28EFF',
      companyGreen: '#89B410',
      emeraldGreen: '#22CB84',
      yellowGold: '#FFE500',
      blue: '#0085FF',
      greenWeak: '#7DFFA9',
      greenDark: '#00AA4D',
      greenAvocado: '#A5DC86',
      lightPink: '#C38FDB',
    },
  },
}

export default themeConfig
