import {
  mdiAccount,
  mdiBallot,
  mdiBriefcaseAccount,
  mdiCardTextOutline,
  mdiCheckbook,
  mdiCogs,
  mdiDownloadBox,
  mdiFileEdit,
  mdiFormatListBulletedType,
  mdiTicketAccount,
  mdiTicketConfirmation,
  mdiTicketPercent,
  mdiViewDashboardOutline,
} from '@mdi/js'

import localStorageSlim from 'localstorage-slim'

const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
const hierarchicalProfile = userProfile?.hierarchical_profile

const personalDepartment = [
  {
    subheader: 'Departamento Pessoal',
  },
  {
    title: 'Vales',
    icon: mdiTicketAccount,
    children: [
      {
        title: 'Registrar | Acompanhar',
        to: 'personal-department-home',
        hidden: false,
        icon: mdiTicketConfirmation,
      },
    ],
  },
  {
    title: 'DP',
    icon: mdiBriefcaseAccount,
    children: [],
  },
]

if (userProfile) {
  const profile = userProfile.profile?.profile

  if (profile === 'Controladoria') {
    personalDepartment.push({
      title: 'Controladoria',
      icon: mdiBallot,
      children: [
        {
          title: 'Conferencia Folha',
          icon: mdiCardTextOutline,
          to: 'report-sheet',
          hidden: true,
        },
      ],
    })
  }

  if (profile === 'Diretor') {
    personalDepartment[1]?.children.unshift({
      title: 'Dashboard',
      to: 'personal-department-dash',
      icon: mdiViewDashboardOutline,
    })
  }

  if (hierarchicalProfile === 'nivel_2') {
    personalDepartment[2]?.children.unshift({
      title: 'Digitação Folha',
      to: 'personal-department-sheetTyping',
      icon: mdiFileEdit,
    })

    personalDepartment[2]?.children.push({
      title: 'Exportação Folha',
      to: 'personal-department-export-commissions',
      icon: mdiDownloadBox,
    })
  }

  if (profile === 'Departamento Pessoal') {
    personalDepartment[2]?.children.push(
      {
        title: 'Exportação Vales',
        to: 'personal-department-dp',
        icon: mdiTicketAccount,
      },
      {
        title: 'Funcionários',
        to: 'users-dp',
        icon: mdiAccount,
      },
      {
        title: 'Ficha Financeira',
        to: 'financial-statement',
        icon: mdiCheckbook,
      },
      {
        title: 'Lançamentos',
        to: 'personal-department-dp-now',
        icon: mdiTicketPercent,
      },
      {
        title: 'Eventos',
        to: 'personal-department-dp-events',
        icon: mdiCogs,
      },
      {
        title: 'Tipos',
        to: 'personal-department-dp-types',
        icon: mdiFormatListBulletedType,
      },
    )
  }

  if (profile === 'Recursos Humanos') {
    personalDepartment[2]?.children.push(
      {
        title: 'Ficha Financeira',
        to: 'financial-statement',
        icon: mdiCheckbook,
      },
      {
        title: 'Funcionários',
        to: 'users-dp',
        icon: mdiAccount,
      },
    )
  }
}

export default personalDepartment
