const nexusapp = {
  path: '/nexusapp',
  component: () => import('@/views/pages/nexus-app/NexusAppHome.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'home',
      name: 'nexusapp-home',
      component: () => import('@/views/pages/nexus-app/Home/vehicles/Vehicle.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'plans',
      name: 'nexusapp-plains',
      component: () => import('@/views/pages/nexus-app/Home/plans/Plans.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'installments',
      name: 'nexusapp-installments',
      component: () => import('@/views/pages/nexus-app/Home/installments/Installments.vue'),
      meta: {
        layout: 'content',
      },
    },
  ],
}

export default nexusapp
