import {
  mdiBullseyeArrow,
  mdiCogOutline,
  mdiTelevision,
  mdiTextBoxSearchOutline,
} from '@mdi/js'

import localStorageSlim from 'localstorage-slim'

const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
const profileName = userProfile?.profile?.profile

const utilities = [
  {
    subheader: 'Utilitários',
  },

  // {
  //   title: 'CAMPANHAS',
  //   icon: mdiSendOutline,
  //   children: [
  //     {
  //       title: '1° Revisão',
  //       to: 'utilities-home',
  //       hidden: true,
  //     },
  //   ],
  // },
]

if (profileName === 'Administrativo' || profileName === 'Pós-venda' || profileName === 'Administrador') {
  utilities.push({
    title: 'PEÇAS',
    icon: mdiCogOutline,
    children: [
      {
        title: 'Separar',
        to: 'parts-order',
        hidden: false,
      },
    ],
  })
}
if (profileName === 'Auditoria' || profileName === 'Administrador' || profileName === 'Controladoria' || profileName === 'Diretor') {
  utilities.push({
    title: 'LOGS',
    icon: mdiTextBoxSearchOutline,
    children: [
      {
        title: 'Erro',
        to: 'logs-error',
        hidden: true,
      },
      {
        title: 'Sucesso',
        to: 'logs-success',
        hidden: true,
      },
    ],
  })
}

// const verifyIsMarketing = profileName === 'Marketing' || profileName === 'Administrador' || profileName === 'Diretor'

if (profileName === 'Marketing' || profileName === 'Administrador' || profileName === 'Diretor') {
  utilities.push({
    title: 'MARKETING',
    icon: mdiBullseyeArrow,
    children: [
      {
        title: 'NEXUS PLAYER',
        icon: mdiTelevision,
        hidden: true,
        children: [
          {
            title: 'Inserir mídias',
            to: 'insert-media',
            hidden: true,
          },
          {
            title: 'Exibição',
            to: 'display-media',
            hidden: true,
          },
        ],
      },
    ],
  })
} else {
  utilities.push({
    title: 'MARKETING',
    icon: mdiBullseyeArrow,
    children: [
      {
        title: 'NEXUS PLAYER',
        icon: mdiTelevision,
        hidden: true,
        children: [
          {
            title: 'Exibição',
            to: 'display-media',
            hidden: true,
          },
        ],
      },
    ],
  })
}

export default utilities
