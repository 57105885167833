import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"ms-4",attrs:{"bottom":"","color":"success","overlap":"","offset-x":"12","offset-y":"12","dot":""}},[_c(VAvatar,_vm._g(_vm._b({key:_vm.imageProfile,staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},'v-avatar',attrs,false),on),[_c(VImg,{attrs:{"src":_vm.imageProfile}})],1)],1)]}}])},[_c(VList,[_c('div',{staticClass:"pb-3 pt-2"},[_c(VBadge,{staticClass:"ms-4",attrs:{"bottom":"","color":"success","overlap":"","offset-x":"12","offset-y":"12","dot":""}},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[_c(VImg,{attrs:{"src":_vm.imageProfile}})],1)],1),_c('div',{staticClass:"d-inline-flex flex-column justify-center ms-3",staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text--primary font-weight-semibold mb-n1"},[_vm._v(" "+_vm._s(_vm.username)+" ")]),_c('small',{staticClass:"text--disabled text-capitalize"},[_vm._v("Admin")])])],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'settings-profile' })}}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircleOutline)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Perfil")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'documents-profile' })}}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentMultipleOutline)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Documentos")])],1)],1),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Sair")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }