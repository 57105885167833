const searchClient = {
  path: '/explorer',
  component: () => import('@/views/pages/SearchClient/SearchClient.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'home',
      name: 'explorer-home',
      component: () => import('@/views/pages/SearchClient/Home/index.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'list',
      name: 'explorer-list',
      component: () => import('@/views/pages/SearchClient/Home/listData.vue'),
      meta: {
        layout: 'content',
      },
      props: true,
    },
  ],
}

export default searchClient
