const personalDepartment = {
  path: '/personalDepartment',
  component: () => import('@/views/pages/personalDepartment/routes/personalDepartmentRouter.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'dashboard',
      name: 'personal-department-dash',
      component: () => import('@/views/pages/personalDepartment/Dashboard/Dashboard.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'home',
      name: 'personal-department-home',
      component: () => import('@/views/pages/personalDepartment/Tickets/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'dp',
      name: 'personal-department-dp',
      component: () => import('@/views/pages/personalDepartment/ExportTickets/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'dp-now',
      name: 'personal-department-dp-now',
      component: () => import('@/views/pages/personalDepartment/TicketFromNow/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'dp-event-voucher',
      name: 'personal-department-dp-events',
      component: () => import('@/views/pages/personalDepartment/Administration/events/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'dp-type-voucher',
      name: 'personal-department-dp-types',
      component: () => import('@/views/pages/personalDepartment/Administration/types/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'sheetTyping',
      name: 'personal-department-sheetTyping',
      component: () => import('@/views/pages/personalDepartment/sheetTyping/Home.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'export-commissions',
      name: 'personal-department-export-commissions',
      component: () => import('@/views/pages/personalDepartment/ExportCommissions/Home.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'reportSheet',
      name: 'report-sheet',
      component: () => import('@/views/pages/personalDepartment/ControllingSector/ReportSheet/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'usersPersonalDepartment',
      name: 'users-dp',
      component: () => import('@/views/pages/personalDepartment/Users/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'financial-dp',
      name: 'financial-statement',
      component: () => import('@/views/pages/personalDepartment/FinancialStatement/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
  ],
}

export default personalDepartment
