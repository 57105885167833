import {
  mdiAccountCog, mdiAccountDetailsOutline, mdiAlertRemove,
  mdiCurrencyUsd,
  mdiHomeOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Ferramentas de integração',
  },
  {
    title: 'Dashboard',
    to: 'integrator-dashboard',
    icon: mdiHomeOutline,
  },
  {
    title: 'Comissões-CNH',
    to: 'commissions-home',
    icon: mdiAccountCog,
  },

  {
    title: 'Entregas',
    to: 'list-bonifications',
    icon: mdiAccountDetailsOutline,
  },
  {
    title: 'Inconsistências',
    to: 'list-inconsistencies',
    icon: mdiAlertRemove,
  },
  {
    title: 'BONIFICAÇÕES',
    icon: mdiCurrencyUsd,
    children: [
      {
        title: '2° e 5° parcela',
        to: 'list-commission-quote',
      },
      {
        title: 'Crescimento',
        to: 'sigma-params-growth',
      },
      {
        title: 'Top 3',
        to: 'top-three',
      },
    ],
  },

  // NOTE - Aba removida do integrador, mas permanece no projeto para uso futuro
  // {
  //   title: 'Comissões',
  //   to: 'integrator-commissions',
  //   icon: mdiFileExport,
  // },
]
