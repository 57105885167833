import { mdiSearchWeb } from '@mdi/js'

export default [
  {
    subheader: 'Buscador',
  },
  {
    title: 'Consultar',
    to: 'explorer-home',
    icon: mdiSearchWeb,
  },
]
