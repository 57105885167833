const admin = {
  path: '/adm',
  component: () => import('@/views/pages/sys-admin/AdmHome.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'dashboard',
      name: 'sys-adm-dashboard',
      component: () => import('@/views/pages/sys-admin/AdmDashboard.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'grupo',
      name: 'sys-adm-group',
      component: () => import('@/views/pages/administrator/group/groupHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'region',
      name: 'sys-adm-region',
      component: () => import('@/views/pages/administrator/region/regionHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'company',
      name: 'sys-adm-company',
      component: () => import('@/views/pages/administrator/company/companyHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'profile',
      name: 'sys-adm-profile',
      component: () => import('@/views/pages/administrator/profile/profileHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'users',
      name: 'sys-adm-user',
      component: () => import('@/views/pages/administrator/user/userHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'systems',
      name: 'sys-adm-system',
      component: () => import('@/views/pages/administrator/system/Home.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'url',
      name: 'sys-adm-url',
      component: () => import('@/views/pages/administrator/url/urlHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'sectors',
      name: 'sys-adm-sector',
      component: () => import('@/views/pages/administrator/Sector/Home.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'occupations',
      name: 'sys-adm-occupation',
      component: () => import('@/views/pages/administrator/occupation/occupationHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'user-access',
      name: 'sys-adm-user-access',
      component: () => import('@/views/pages/administrator/userAccess/userAccessHome.vue'),
      meta: { layout: 'content' },
    },
  ],
}

export default admin
