import {
  mdiAccount,
  mdiAccountCheck,
  mdiAccountCog,
  mdiAccountGroupOutline,
  mdiAccountKey,
  mdiApps,
  mdiDomain,
  mdiEarthPlus,
  mdiFileTableBoxMultiple,
  mdiHomeOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Ferramentas do Administrador',
  },
  {
    title: 'Dashboard',
    to: 'sys-adm-dashboard',
    icon: mdiHomeOutline,
  },
  {
    title: 'Sistemas',
    to: 'sys-adm-system',
    icon: mdiApps,
  },
  {
    title: 'Grupos',
    to: 'sys-adm-group',
    icon: mdiAccountGroupOutline,
  },
  {
    title: 'Regiões',
    to: 'sys-adm-region',
    icon: mdiEarthPlus,
  },
  {
    title: 'Empresas',
    to: 'sys-adm-company',
    icon: mdiDomain,
  },

  {
    subheader: 'Controle de Acesso',
  },

  {
    title: 'Perfis',
    to: 'sys-adm-profile',
    icon: mdiAccountCheck,
  },

  {
    title: 'Usuários',
    to: 'sys-adm-user',
    icon: mdiAccount,
  },

  {
    title: 'Sistemas de acesso',
    to: 'sys-adm-user-access',
    icon: mdiAccountKey,
  },

  {
    title: 'Ocupação / Função',
    to: 'sys-adm-occupation',
    icon: mdiAccountCog,
  },

  {
    title: 'Setores',
    to: 'sys-adm-sector',
    icon: mdiFileTableBoxMultiple,
  },

  {
    title: 'URLs',
    to: 'sys-adm-url',
    icon: mdiApps,
  },
]
