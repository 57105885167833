const searchClient = {
  path: '/utilities',
  component: () => import('@/views/pages/Utilities/UtilitiesRoute.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'home',
      name: 'utilities-home',
      component: () => import('@/views/pages/Utilities/Marketing/Media/display/DisplayMedia.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'parts-order',
      name: 'parts-order',
      component: () => import('@/views/pages/sgqSystem/PastsOrder/home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'logs-success',
      name: 'logs-success',
      component: () => import('@/views/pages/Utilities/Logs/tabs/Success/LogsSucces.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'logs-error',
      name: 'logs-error',
      component: () => import('@/views/pages/Utilities/Logs/tabs/Error/LogsError.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'insert-media',
      name: 'insert-media',
      component: () => import('@/views/pages/Utilities/Marketing/Media/insert-media/InsertMedia.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'display-media',
      name: 'display-media',
      component: () => import('@/views/pages/Utilities/Marketing/Media/display/DisplayMedia.vue'),
      meta: {
        layout: 'content',
      },
    },
  ],
}

export default searchClient
