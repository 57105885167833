const sigmaVendor = {
  path: '/nexus',
  component: () => import('@/views/pages/sigma/sigmaHome.vue'),
  children: [
    {
      path: 'vendedor/clientes',
      name: 'sigma-client',
      component: () => import('@/views/pages/sigma/client/clientHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/contratos',
      name: 'sigma-contract',
      component: () => import('@/views/pages/sigma/contract/contractHome.vue'),
      meta: { layout: 'content' },
    },

    {
      path: 'vendedor/retencao',
      name: 'sigma-retention',
      component: () => import('@/views/pages/sigma/retention/retentionHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/assembleia',
      name: 'sigma-assembly',
      component: () => import('@/views/pages/sigma/after-sales/assembly/AssemblyHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/encerramento',
      name: 'sigma-closing-group',
      component: () => import('@/views/pages/sigma/after-sales/closing-group/ClosingGroupHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/grupo-reserva',
      name: 'sigma-reserve-group',
      component: () => import('@/views/pages/sigma/after-sales/reserve-group/ReserveGroupHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/birthday-person',
      name: 'sigma-birthday-person',
      component: () => import('@/views/pages/sigma/after-sales/birthday-person/BirthdayPersonHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/dashboard',
      name: 'sigma-vendor',
      component: () => import('@/views/pages/sigma/vendor/VendorHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/lottery',
      name: 'sigma-lottery',
      component: () => import('@/views/pages/sigma/after-sales/lottery/LotteryHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/termo_lgpd_vendedor',
      name: 'sigma-lgpd-vendor',
      component: () => import('@/views/pages/sigma/adm-cnh/lgpd/LgpdVendor.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/calendar',
      name: 'calendar-vendor',
      component: () => import('@/views/pages/sigma/calendar/Calendar.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'vendedor/carteira',
      name: 'wallet-client',
      component: () => import('@/views/pages/sigma/after-sales/wallet-client/WalletClient.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'perfil',
      name: 'settings-profile',
      component: () => import('@/views/pages/profiles/ProfileSettings.vue'),
      meta: { layout: 'content' },
    },

  ],
}

export default sigmaVendor
